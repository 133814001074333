<template>
  <div class="pad-container">
    <template v-for="item in storeList">
      <el-row class="pd-20">
        <label>{{item.storeName}}</label>
        <el-input
            placeholder="请输入配送费"
            type="number"
            v-model="item.supermarketBaseDeliveryFee==null?0:item.supermarketBaseDeliveryFee"
            style="width: 150px">
          <template slot="append">元</template>
        </el-input>
      </el-row>
    </template>
    <div>
      <el-button type="primary" @click="checkForm">保存</el-button>
    </div>
  </div>
</template>

<script>
  import {queryAllDeliveryFee,updateAllDeliveryFee} from "@/api/market/delivery_fee_config";

  export default {
    name: "delivery_fee_config",
    data() {
      return {
        storeList: null
      }
    },
    mounted() {
      this.getAllDeliveryFee()
    },
    methods: {
      getAllDeliveryFee() {
        queryAllDeliveryFee().then(res => {
          console.log(res)
          this.storeList = res.data.body
        })
      },
      checkForm() {
        //好像也没啥好校验的
        updateAllDeliveryFee(this.storeList)
      }
    }
  }

</script>

<style scoped>

</style>